<template>
  <custom-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="idTypes"
    :value="value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      idTypes: [
        { title: 'National ID Card', value: 'national_id_card' },
        { title: 'Driver’s License', value: 'drivers_license' },
        { title: 'Permanent Voter’s Card', value: 'voters_card' },
        { title: 'Nigerian International Passport', value: 'international_passport' },
      ],
    };
  },
};
</script>
